import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, TextField } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import AllInputProps from '../../utils/inputProps'
import { currentLanguages } from '../../i18n/config/currentLanguages'

const StayConnectedCard = () => {
    const { t, i18n } = useTranslation('jmsm')
    const currentLangPath = currentLanguages.find(lang => lang.shorthand === i18n.language).path

    return (
        <div id="stayConnectedCard" className="card stayConnectedCard">
            <h3 className="cardTitle">{t('stayConnected')}</h3>
            <p className="cardParagraph">{t('emailDescription')}</p>
            <form
                name="JMSM-launch"
                method="post"
                className="emailForm"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                action={`${currentLangPath}/merci`}
            >
                {/* Need to have this hidden input with the form name to your JSX form */}
                <input type="hidden" name="form-name" value="JMSM-launch" />
                <TextField
                    required
                    fullWidth
                    name="Email"
                    type="email"
                    id="emailsForm-email"
                    label={t('misc:email')}
                    variant="outlined"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...AllInputProps}
                />
                <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    endIcon={<NavigateNextIcon />}
                    disableElevation
                    classes={{ root: 'emailButtonRoot', contained: 'emailButtonContained' }}
                >
                    {t('informButton')}
                </Button>
            </form>
        </div>
    )
}

export default StayConnectedCard

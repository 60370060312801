import React from 'react'
import { Button } from '@material-ui/core'

const FloatingButton = ({ label, ...props }) => {
    return (
        <Button
            size="large"
            variant="contained"
            disableElevation
            classes={{ root: 'floatingButtonRoot', contained: 'floatingButtonContained' }}
            {...props}
        >
            {label}
        </Button>
    )
}
export default FloatingButton

import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
    // Accordion,
    // AccordionSummary,
    // AccordionDetails,
    Button,
    Dialog,
    IconButton,
} from '@material-ui/core'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import CloseIcon from '@material-ui/icons/Close'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Layout from '../components/layout'
import SEO from '../components/seo'
// import scrollTo from '../utils/scrollTo'
import Video from '../components/video/Video'
// import OutlinedButton from '../components/outlinedButton/OutlinedButton'
import LogoGridCard from '../components/cards/LogoGridCard'
import StayConnectedCard from '../components/cards/StayConnectedCard'
import ParticipantsCard from '../components/cards/ParticipantsCard/ParticipantsCard'
import FloatingButton from '../components/floatingButton/FloatingButton'
// import Image from '../components/image'
import JMSMPreview from '../assets/videos/jmsm_preview.mp4'
import OurClientsCard from '../components/cards/OurClientsCard'

import JMSM from '../assets/svg/jmsm.svg'
import MinisterPhone from '../assets/svg/phones/ministerPhone.svg'
import NavigationPhone from '../assets/svg/phones/navigationPhone.svg'
import HomeworkPhone from '../assets/svg/phones/homeworkPhone.svg'
import WonderWoman from '../assets/svg/wonderWoman.svg'
import PlantGuy from '../assets/svg/plantGuy.svg'
import FlashlightGuy from '../assets/svg/flashlightGuy.svg'
import MatrixWoman from '../assets/svg/matrixWoman.svg'
import Puzzle from '../assets/svg/puzzle.svg'
import Discussion from '../assets/svg/discussion.svg'
// import LaRuche from '../assets/svg/supporters/laruche.svg'

function JeMiseSurMoi() {
    const { t } = useTranslation('jmsm')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const onClose = () => setIsModalOpen(false)
    const value = 'Je mise sur moi'

    return (
        <Layout>
            <SEO title="jmsm:title" description="jmsm:description" image="/metaImages/jmsm.png" />
            <FloatingButton
                label={t('misc:shop')}
                startIcon={<LocalMallIcon />}
                target="_blank"
                href="https://www.extrapole.app/je-mise-sur-moi"
            />
            <div className="jmsmHeader">
                <JMSM className="jmsmLogo" />
                <span className="jmsmSubtitle">{t('tagline1')}</span>
                <span className="jmsmSubtitle2">{t('tagline2')}</span>
                <Button
                    size="large"
                    variant="contained"
                    startIcon={<LocalMallIcon />}
                    disableElevation
                    target="_blank"
                    href="https://www.extrapole.app/je-mise-sur-moi"
                    classes={{ root: 'signUpButtonRoot', contained: 'signUpButtonContained' }}
                >
                    {t('misc:shop')}
                </Button>
            </div>
            <div className="card largeVideoPreview">
                <video
                    // src="/static/jmsm_preview.mp4"
                    className="backgroundVideo"
                    autoPlay
                    muted
                    playsInline
                    loop
                    aria-hidden="true"
                >
                    <source src={JMSMPreview} type="video/mp4" />
                </video>
                <Button
                    size="large"
                    variant="contained"
                    startIcon={<PlayArrowIcon />}
                    disableElevation
                    onClick={() => setIsModalOpen(true)}
                    classes={{ root: 'watchButtonRoot', contained: 'watchButtonContained' }}
                >
                    {t('misc:watch')}
                </Button>
            </div>
            <div className="cardGrid">
                <div className="card whyJMSMCard">
                    <h3 className="cardTitle">
                        <Trans i18nKey="jmsm:whyJMSMCardTitle" value={value}>
                            Why <i>{{ value }}</i> ?
                        </Trans>
                    </h3>
                    <p className="cardParagraph">{t('whyJMSMCardDescription')}</p>
                    <NavigationPhone />
                </div>
                <div className="card winkCard">
                    <h3 className="cardTitle">{t('winkCardTitle')}</h3>
                    <p className="cardParagraph">{t('winkCardDescription')}</p>
                    <HomeworkPhone />
                </div>
            </div>
            <ParticipantsCard />
            <div className="cardGrid">
                <div className="card ministerCard">
                    <h3 className="cardTitle">{t('ministerCardTitle')}</h3>
                    <p className="cardParagraph">{t('ministerCardDescription')}</p>
                    <MinisterPhone />
                </div>
                <div className="card testimonialsCard">
                    {[...Array(3)].map((o, id) => (
                        <div key={`testimony${id + 1}`} className="testimony">
                            <p className="testimonyParagraph">
                                {t(`testimonies.testimony${id + 1}`)}
                            </p>
                            <span className="testimonyAuthor">{`- ${t(
                                `testimonies.author${id + 1}`,
                            )}`}</span>
                            <span className="testimonyJob">{t(`testimonies.job${id + 1}`)}</span>
                        </div>
                    ))}
                </div>
                {/* </div> */}
                {/* <Accordion
                classes={{ root: 'cardAccordionRoot', expanded: 'cardAccordionExpanded' }}
                TransitionProps={{ unmountOnExit: true }}
                elevation={0}
            >
                <AccordionSummary
                    classes={{
                        root: 'cardAccordionSummaryRoot',
                        expanded: 'cardAccordionSummaryExpanded',
                        content: 'cardAccordionSummaryContent',
                        expandIcon: 'cardAccordionSummaryIcon',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {t('discoverHow')}
                </AccordionSummary>
                <AccordionDetails className="cardAccordionDetails"> */}
                {/* <div className="cardGrid"> */}
                <div className="card takeFutureCard">
                    <h3 className="cardTitle">{t('takeFutureTitle')}</h3>
                    <p className="cardParagraph">{t('takeFutureDescription')}</p>
                    <WonderWoman />
                </div>
                <div className="card tomorrowCard">
                    <h3 className="cardTitle">{t('tomorrowTitle')}</h3>
                    <p className="cardParagraph">{t('tomorrowDescription')}</p>
                    <PlantGuy />
                </div>
                <div className="card makeChoicesCard">
                    <h3 className="cardTitle">{t('makeChoicesTitle')}</h3>
                    <p className="cardParagraph">{t('makeChoicesDescription')}</p>
                    <FlashlightGuy />
                </div>
                <div className="card orientationCard">
                    <h3 className="cardTitle">{t('orientationTitle')}</h3>
                    <p className="cardParagraph">{t('orientationDescription')}</p>
                    <MatrixWoman />
                </div>
                <div className="card wellSurroundedCard">
                    <h3 className="cardTitle">{t('wellSurroundedTitle')}</h3>
                    <p className="cardParagraph">{t('wellSurroundedDescription')}</p>
                    <Puzzle />
                </div>
                <div className="card haveDiscussionCard">
                    <h3 className="cardTitle">{t('haveDiscussionTitle')}</h3>
                    <p className="cardParagraph">{t('haveDiscussionDescription')}</p>
                    <Discussion />
                </div>
            </div>
            {/* </AccordionDetails>
            </Accordion> */}
            <OurClientsCard />
            <LogoGridCard label={t('supportedBy')} />
            {/* <div className="card laRucheCard">
                    <div>
                        <h3 className="cardTitle">{t('supportOnLaRuche')}</h3>
                        <p className="cardParagraph">{t('supportOnLaRucheParagraph')}</p>
                        <OutlinedButton />
                    </div>
                    <LaRuche className="laRucheLogo" />
                </div> */}
            {/* <div className="cardGrid">
                    <div className="card schoolRepCard">
                        <h3 className="cardTitle">{t('schoolRepTitle')}</h3>
                        <p className="cardParagraph">{t('schoolRepDescription')}</p>
                        <OutlinedButton />
                    </div>
                    <div className="card moreInfoCard">
                        <h3 className="cardTitle">{t('moreInfoTitle')}</h3>
                        <p className="cardParagraph">{t('moreInfoDescription')}</p>
                        <OutlinedButton />
                    </div>
                </div> */}
            <StayConnectedCard />
            <Dialog
                open={isModalOpen}
                onClose={onClose}
                aria-labelledby="dialog-video"
                BackdropProps={{ className: 'modalBackdrop' }}
                classes={{ root: 'videoModal', paper: 'videoPaper' }}
                TransitionProps={{ unmountOnExit: true }}
                maxWidth="md"
            >
                <Video url="https://vimeo.com/529388741/018529389e?autoplay=1" />
                <IconButton onClick={onClose} className="closeIcon">
                    <CloseIcon />
                </IconButton>
            </Dialog>
        </Layout>
    )
}

export default JeMiseSurMoi

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Parallax } from 'react-scroll-parallax'

import Anna from './images/Anna'
import Jade from './images/Jade'
import Julien from './images/Julien'
import Kev from './images/Kev'
import Manu from './images/Manu'
import Thierry from './images/Thierry'
import Will from './images/Will'

const ParticipantsCard = () => {
    const { t } = useTranslation('jmsm')
    return (
        <div className="card participantsCard">
            <h3 className="cardTitle">{t('participantsCardTitle')}</h3>
            <p className="cardParagraph">{t('participantsCardDescription')}</p>
            <Parallax className="anna" y={[-40, 40]} tagOuter="figure">
                <Anna className="participant " />
            </Parallax>
            <Parallax className="jade" y={[-50, 50]} tagOuter="figure">
                <Jade className="participant" />
            </Parallax>
            <Parallax className="julien" y={[-25, 25]} tagOuter="figure">
                <Julien className="participant" />
            </Parallax>
            <Parallax className="kev" y={[-50, 50]} tagOuter="figure">
                <Kev className="participant" />
            </Parallax>
            <Parallax className="manu" y={[-35, 35]} tagOuter="figure">
                <Manu className="participant" />
            </Parallax>
            <Parallax className="thierry" y={[-40, 40]} tagOuter="figure">
                <Thierry className="participant" />
            </Parallax>
            <Parallax className="will" y={[-30, 30]} tagOuter="figure">
                <Will className="participant" />
            </Parallax>
        </div>
    )
}

export default ParticipantsCard

import React from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import StJeanEude from '../../assets/svg/supporters/stJeanEude.svg'
import MSS from '../../assets/svg/supporters/mss.svg'
import StAnne from '../../assets/svg/supporters/stAnne.svg'
import Regina from '../../assets/svg/supporters/regina.svg'
import SaintCharles from '../../assets/svg/supporters/saintCharles.svg'

const OurClientsCard = () => {
    const { t } = useTranslation('misc')
    return (
        <div className="card ourClientsCard">
            <h3 className="cardTitle">{t('ourClients')}</h3>
            <div className="logoGrid">
                <StJeanEude />
                <StAnne />
                <Regina />
                <SaintCharles />
                <MSS />
            </div>
        </div>
    )
}

export default OurClientsCard

// OurClientsCard.propTypes = {
//     label: PropTypes.string,
// }

// OurClientsCard.defaultProps = {
//     label: '',
// }
